import { createBrowserRouter } from "react-router-dom";
import ProjectDetail from "./ProjectDetail";
import Projects from "./Projects";
import Contact from "./Contact";
import Home from "./Home";
import ErrorPage from "./ErrorPage";
import RootLayout from "./RootLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/detail/:slug",
        element: <ProjectDetail />,
      },
    ],
  },
]);

export default router;
