import { NavLink } from "react-router-dom";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export default function Navbar() {
  const classnames =
    "inline-flex items-center   px-1 pt-1 text-sm font-medium text-gray-900";
  const classnames2 = "block border-l-4  py-2 pl-3 pr-4 text-base font-medium ";

  return (
    <Disclosure as="nav" className=" shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className=" flex flex-1 items-center justify-center sm:items-stretch sm:justify-between">
                <div className="flex flex-shrink-0 items-center text-3xl">
                  <p className="block  lg:hidden">Haruna</p>
                  <p className="hidden  lg:block">Haruna</p>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? `border-indigo-500 border-b-2 ${classnames}`
                        : classnames
                    }
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/projects"
                    className={({ isActive }) =>
                      isActive
                        ? `border-indigo-500 border-b-2 ${classnames}`
                        : classnames
                    }
                  >
                    Projects
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      isActive
                        ? `border-indigo-500 border-b-2 ${classnames}`
                        : classnames
                    }
                  >
                    Contact
                  </NavLink>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  ></Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-4">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? `border-indigo-500 bg-indigo-50 text-indigo-700 ${classnames2}`
                    : classnames2
                }
              >
                <Disclosure.Button as="a" href="# border-indigo-500">
                  Home
                </Disclosure.Button>
              </NavLink>
              <NavLink
                to="/projects"
                className={({ isActive }) =>
                  isActive
                    ? `border-indigo-500 bg-indigo-50 text-indigo-700 ${classnames2}`
                    : classnames2
                }
              >
                <Disclosure.Button as="a" href="#">
                  Work
                </Disclosure.Button>
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? `border-indigo-500 bg-indigo-50 text-indigo-700 ${classnames2}`
                    : classnames2
                }
              >
                <Disclosure.Button as="a" href="#">
                  Contact
                </Disclosure.Button>
              </NavLink>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
