import description from "./description";
import one_png from "./images/1.png";
import two_png from "./images/2.png";
import three_png from "./images/3.png";
import four_png from "./images/4.png";
import five_png from "./images/5.png";

const ecommerceCopyData = {
  description,
  title: "E-commerce Copy Store",
  slug: "ecommerce-store-copy",
  github_url: "#",
  images: [
    { name: "one", url: one_png },
    { name: "two", url: two_png },
    { name: "three", url: three_png },
    { name: "four", url: four_png },
    { name: "five", url: five_png },
  ],
};

export { ecommerceCopyData };
