import python from "../assets/python-96.png";
import react from "../assets/react-100.png";
import django from "../assets/django-100.png";
import postgresql from "../assets/postgresql-96.png";
import docker from "../assets/docker-96.png";
import aws from "../assets/aws-96.png";
import javascript from "../assets/javascript-logo-100.png";
import tailwindcss from "../assets/tailwindcss-96.png";
import redis from "../assets/redis-96.png";
import github from "../assets/github-128.png";
import html from "../assets/html-5-96.png";
import css from "../assets/css3-96.png";

const techStack = [
  {
    name: "Python",
    icon: python,
    description: "I use Python as my primary language.",
  },
  {
    name: "Django",
    icon: django,
    description: "I use Django as my primary backend framework.",
  },
  {
    name: "JavaScript",
    icon: javascript,
    description: "I use JavaScript as my primary language.",
  },
  {
    name: "React",
    icon: react,
    description: "I use React as my primary frontend framework.",
  },
  {
    name: "PostgreSQL",
    icon: postgresql,
    description: "I use PostgreSQL as my primary database.",
  },
  {
    name: "Redis",
    icon: redis,
    description: "I use Redis as my primary caching tool.",
  },
  {
    name: "Docker",
    icon: docker,
    description: "I use Docker as my primary containerization tool.",
  },
  {
    name: "AWS",
    icon: aws,
    description: "I use AWS as my primary cloud provider.",
  },
  {
    name: "HTML",
    icon: html,
    description: "I use HTML as my primary markup language.",
  },
  {
    name: "CSS",
    icon: css,
    description: "I use CSS as my primary styling language.",
  },
  {
    name: "TailwindCSS",
    icon: tailwindcss,
    description: "I use TailwindCSS as my primary CSS framework.",
  },
  {
    name: "GitHub",
    icon: github,
    description: "I use GitHub as my primary version control tool.",
  },
];

export { techStack };
