import { Link } from "react-router-dom";
import rone from "./rone.JPG";
import { techStack } from "./tech_stack_data";

export default function Home() {
  return (
    <>
      <div className="relative bg-white py-16">
        <div
          className="absolute inset-x-0 top-0 hidden h-1/2 bg-gray-50 lg:block"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl bg-blue-600 lg:bg-transparent lg:px-8">
          <div className="lg:grid lg:grid-cols-12">
            <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
              <div
                className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
                aria-hidden="true"
              />
              <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:p-0">
                <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                  <img
                    className="rounded-3xl object-cover object-center shadow-2xl"
                    src={rone}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="relative bg-blue-600 lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
              <div
                className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute bottom-full left-full translate-y-1/3 -translate-x-2/3 transform xl:bottom-auto xl:top-0 xl:translate-y-0"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-blue-500"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                  />
                </svg>
                <svg
                  className="absolute top-full -translate-y-1/3 -translate-x-1/3 transform xl:-translate-y-1/2"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-blue-500"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                  />
                </svg>
              </div>
              <div className="relative mx-auto max-w-md space-y-6 py-12 px-6 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
                <h2
                  className="text-3xl font-bold tracking-tight text-white"
                  id="join-heading"
                >
                  Hi, there! I'm Haruna...
                </h2>
                <p className="text-lg text-white">
                  I am a software developer with a passion for building web
                  apps. I am also a blogger.
                </p>
                <Link
                  className="block w-full rounded-md border border-transparent bg-white py-3 px-5 text-center text-base font-medium text-blue-700 shadow-md hover:bg-gray-50 sm:inline-block sm:w-auto"
                  to="/projects"
                >
                  View My Projects
                </Link>
                <a
                  className="block sm:mx-5 w-full rounded-md border border-transparent bg-white py-3 px-5 text-center text-base font-medium text-blue-700 shadow-md hover:bg-gray-50 sm:inline-block sm:w-auto"
                  href="https://harunacodes.com/"
                  target={"_blank"}
                >
                  View My Blog
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-white py-24 sm:py-10 ">
        <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Tech Stack
          </p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500 mb-5">
            My day-to-day tech stack includes the following technologies...
          </p>
        </div>
        <div className="container mx-auto mb-24   ">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            {techStack.map((stack) => (
              <div
                key={stack.name}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={stack.icon}
                    alt=""
                  />
                </div>
                <div className="min-w-0 flex-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {stack.name}
                  </p>
                  {/* <p className="truncate text-sm text-gray-500">{person.role}</p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <Link
            className="block w-4/5 sm:w-60 mx-auto rounded-md border border-transparent py-3 px-5 text-center text-base font-medium text-white bg-blue-700 shadow-md hover:bg-gray-50 sm:inline-block "
            to="/projects"
          >
            View My Projects
          </Link>
        </div>
      </div>
    </>
  );
}
