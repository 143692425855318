import { useParams } from "react-router-dom";
import projects from "./projects/projectsList";

import Slider from "@madzadev/image-slider";
import "@madzadev/image-slider/dist/index.css";

export default function ProjectDetail() {
  const params = useParams();
  const project = projects.find((project) => project.slug === params.slug);

  const paragraphs = project.description.map((paragraph, index) => {
    return (
      <p key={index} className="mt-8 text-xl leading-8 text-gray-500">
        {paragraph}
      </p>
    );
  });
  return (
    <div>
      <div className="bg-gray-100 sm:pt-10">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-indigo-600">
              Project
            </span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              {project.title}
            </span>
          </h1>
        </div>
        <div className="mx-auto max-w-2xl py-16 px-1 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="grid  lg:gap-x-8">
            <div className=" mx-auto">
              <Slider
                imageList={project.images}
                width={1000}
                height={500}
                bgColor="none"
                // showArrowControls={false}
              />
            </div>
          </div>
          <div className="mx-auto max-w-prose text-lg">
            {paragraphs}
            <a
              className="my-16  block w-full rounded-md border border-transparent bg-white py-3 px-5 text-center text-base font-medium text-blue-700 shadow-md hover:bg-gray-50 sm:inline-block sm:w-auto"
              href={project.github_url}
              target="_blank"
              rel="noreferrer"
            >
              View on Github
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
