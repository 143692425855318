import { Link } from "react-router-dom";

// const Project = ({ project }) => {
//   return (
//     <div
//       key={project.title}
//       className="flex flex-col overflow-hidden rounded-lg shadow-lg"
//     >
//       <div className="flex-shrink-0">
//         <Link to={`/detail/${project.title}`}>
//           <img
//             className="h-48 w-full object-cover"
//             src={`${project.images[0].url}`}
//             alt=""
//           />
//         </Link>
//       </div>
//       <div className="flex flex-1 flex-col justify-between bg-white p-6">
//         <div className="flex-1">
//           <p className="text-xl font-semibold text-gray-900">{project.title}</p>
//           <p className="mt-3 text-base text-gray-500">{project.description}</p>
//         </div>
//         <div className="mt-6 flex items-center">
//           <div className="ml-3">
//             <p className="text-sm font-medium text-gray-900 cursor-pointer">
//               <a href={project.github_url} className="hover:underline flex">
//                 Github Repo
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   strokeWidth={1.5}
//                   stroke="currentColor"
//                   className="w-4 h-5 ml-1 pt-[0.5]"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
//                   />
//                 </svg>
//               </a>
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
// const products = [
//   {
//     id: 1,
//     name: "Fusion",
//     category: "UI Kit",
//     href: "#",
//     price: "$49",
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/product-page-05-related-product-01.jpg",
//     imageAlt:
//       "Payment application dashboard screenshot with transaction table, financial highlights, and main clients on colorful purple background.",
//   },
//   // More products...
// ];

// function Project() {
//   return (
//     <div className="bg-white">
//       {products.map((product) => (
//         <div key={product.id} className="group relative">
//           <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-lg bg-gray-100 hover:opacity-10">
//             <img
//               src={product.imageSrc}
//               alt={product.imageAlt}
//               className="object-cover object-center"
//             />
//             <div className="absolute top-0 w-full h-full bg-slate-300 hover:opacity-30    "></div>
//             <div
//               className="flex items-end p-4 opacity-0 group-hover:opacity-100"
//               aria-hidden="true"
//             >
//               <div className="w-full rounded-md bg-white bg-opacity-70   py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter">
//                 View Product
//               </div>
//             </div>
//           </div>
//           <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900">
//             <h3>
//               <a href="#">
//                 <span aria-hidden="true" className="absolute inset-0" />
//                 {product.name}
//               </a>
//             </h3>
//             <p>{product.price}</p>
//           </div>
//           <p className="mt-1 text-sm text-gray-500">{product.category}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
// const products = [
//   {
//     id: 1,
//     name: "Basic Tee 8-Pack",
//     href: "#",
//     price: "$256",
//     description:
//       "Get the full lineup of our Basic Tees. Have a fresh shirt all week, and an extra for laundry day.",
//     options: "8 colors",
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-01.jpg",
//     imageAlt:
//       "Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.",
//   },
//   {
//     id: 2,
//     name: "Basic Tee",
//     href: "#",
//     price: "$32",
//     description:
//       "Look like a visionary CEO and wear the same black t-shirt every day.",
//     options: "Black",
//     imageSrc:
//       "https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-02.jpg",
//     imageAlt: "Front of plain black t-shirt.",
//   },
//   // More products...
// ];

function Project({ project }) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl  px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Projects</h2>

        <div
          key={project.title}
          className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 "
        >
          <div className="aspect-w-3 aspect-h-4 bg-gray-200  sm:aspect-none sm:h-96">
            <img
              src={`${project.images[0].url}`}
              alt={project.title}
              className="h-full w-full object-cover object-center sm:h-full sm:w-full"
            />
          </div>

          <div className="absolute text-white flex flex-col justify-center items-center w-full h-full bg-black opacity-0   group-hover:opacity-90 ">
            <p className="mb-10 text-2xl">{project.title}</p>
            <Link
              className="  z-20 text-2xl cursor-pointer"
              to={`/detail/${project.slug}`}
            >
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                View Project
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
