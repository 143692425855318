import { configureStore } from "@reduxjs/toolkit";
import { navReducer, toggleNav } from "./slices/navSlice";
import { ecommerceData } from "../Components/projects/ecommerce/data";
import posts from "./data";

const store = configureStore({
  reducer: {
    nav: navReducer,
  },
});

export { store, toggleNav, posts, ecommerceData };
