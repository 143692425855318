import { RouterProvider } from "react-router-dom";

import router from "./Components/Routes";

function App() {
  return (
    //   <div className="">
    //     <Navbar />
    //     <div className="w-full">
    //       <Home />
    //     </div>
    //     <Projects />

    //     <ProjectDetail />
    //   </div>
    // );
    // <RouterProvider router={router} />
    <div>
      <p className="text-center text-6xl">Site under construction.....</p>
    </div>
  );
}

export default App;
